body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

body .container {
  max-height: calc(100vh - 56px);
  max-height: calc(var(--vh, 1vh) * 100 - 56px);
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.container .content {
  overflow-y: auto;
  flex-shrink: 1;
  position: relative;
}
