@media (prefers-reduced-motion: no-preference) {
  .logo .tail.animate {
    animation: wiggle infinite 1.5s ease-in-out;
    transform-origin: 120px 160px;
  }
  .logo .eye.animate .ball {
    transform-origin: 100px 190px;
    animation: wink infinite 20s ease;
  }
  .logo .eye.animate .ball-reflection {
    transform-origin: 102px 192px;
    animation: wink infinite 20s ease;
  }
  .logo .ear.animate {
    transform-origin: 50px 170px;
    animation: flick infinite 20s ease;
  }
}

@keyframes wink {
  0% {
    transform: scale(1, 1);
  }
  1% {
    transform: scale(1, 0.1);
  }
  2% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes flick {
  0% {
    transform: scale(1, 1);
  }
  2% {
    transform: scale(1, 0.7);
  }
  3% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
