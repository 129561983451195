.Login {
  text-align: center;
  background-color: #eee;
}

.Login-logo {
  height: 40vmin;
}

.Login-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
